<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-20 11:08:36
 * @ Description: Fixer's company signup page in landing section of the app.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.lgAndUp"
            style="max-height: 100vh; background-color: rgb(249, 251, 253);"
        >
            <!-- Image -->
            <v-col
                cols="6"
                class="pa-0"
            >
                <v-img
                    src="/landing/signup/general/LandingSignupGeneralBackground_1.webp"
                    width="auto"
                    height="100vh"
                    max-height="100vh"
                />
            </v-col>
            <!-- Image -->

            <!-- Signup Form -->
            <v-col
                cols="6"
                style="max-height: 100vh; overflow-y: auto; display: flex; justify-content: center;"
                align-self="center"
            >
                <v-form
                    ref="signupCompanyForm"
                    v-model="validSignupCompanyForm"
                >
                    <v-row
                        style="height: 100%; max-width: 555px;"
                    >
                        <v-col
                            class="flex-shrink-1 flex-grow-0 pr-0"
                            align-self="end"
                        >
                            <v-img
                                src="/general/FixerCompanyIcon.png"
                                height="35px"
                                width="35px"
                                contain
                            />
                        </v-col>

                        <!-- Company Details -->
                        <v-col
                            class="flex-grow-1 flex-shrink-0 os-22-sb"
                            align-self="end"
                        >
                            Company Details
                        </v-col>
                        <v-col
                            class="flex-shrink-1 flex-grow-0 text-center os-13-r"
                        >
                            <input
                                type="file"
                                style="display: none"
                                ref="avatar"
                                accept="image/*"
                                @change="avatarSelected"
                            >
                            <v-avatar
                                class="mb-2"
                                size="100"
                            >
                                <v-img
                                    :src="avatar ? avatar : '/landing/signup/general/LandingSignupGeneralUploadImage.png'"
                                    contain
                                />
                            </v-avatar>
                            <span @click="selectAvatar" style="color: rgb(143, 155, 179); cursor: pointer;">Upload Image</span>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="6"
                            class="pb-0 os-15-sb"
                        >
                            Company Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="nameRules"
                                v-model="company.company_name"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="pb-0 os-15-sb"
                        >
                            Number of Employees<span style="color: red;">*</span>
                            <br />
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="selectRules"
                                v-model="company.company_size"
                                :items="companySizes"
                                item-color="#2E567A"
                                item-text="name"
                                item-value="id"
                                return-object
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="py-0 os-15-sb"
                        >
                            Company Registration
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                placeholder="This field is highly suggested"
                                v-model="company.company_registration"
                                :rules="registrationRules"
                                counter="50"
                            />
                        </v-col>
                        <!-- Company Details -->

                        <!-- Primary Contact -->
                        <v-col
                            class="os-22-sb"
                            cols="12"
                            align-self="end"
                        >
                            Primary Contact
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="6"
                            class="pb-0 os-15-sb"
                        >
                            First Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="nameRules"
                                v-model="company.first_name"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="pb-0 os-15-sb"
                        >
                            Last Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="nameRules"
                                v-model="company.last_name"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="py-0 os-15-sb"
                        >
                            Email<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :readonly="!canEditEmail"
                                v-model="company.email"
                                :rules="emailRules"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="py-0 os-15-sb"
                        >
                            Contact Number
                            <br />
                            <vue-tel-input-vuetify
                                v-model="company.contact_number_1"
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                @input="contactNumberInput"
                                :rules="contactNumberRules"
                                defaultCountry="za"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="py-0 os-15-sb"
                            v-if="passwordRequired"
                        >
                            New Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="company.password"
                                :append-icon="showPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="py-0 os-15-sb"
                            v-if="passwordRequired"
                        >
                            Confirm Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="confirmPassword"
                                :error-messages="passwordMatchError()"
                                :append-icon="showConfirmPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                @click:append="showConfirmPassword = !showConfirmPassword"
                            />
                        </v-col>
                        <!-- Primary Contact -->

                        <!-- Back/Continue Buttons -->
                        <v-col
                            cols="12"
                            class="text-right pt-0 os-13-sb"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                color="#2E567A"
                                style="width: 103px;"
                                @click="back"
                            >
                                Back
                            </v-btn>
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                style="width: 103px;"
                                @click="signup"
                                :disabled="!validSignupCompanyForm"
                            >
                                Continue
                            </v-btn>
                        </v-col>
                        <!-- Back/Continue Buttons -->
                    </v-row>
                </v-form>
            </v-col>
            <!-- Signup Form -->
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-else-if="$vuetify.breakpoint.mdAndDown"
            style="min-height: 100vh; background-color: rgb(249, 251, 253);"
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                class="py-4"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px'"
            >
                <v-form
                    ref="signupCompanyForm"
                    v-model="validSignupCompanyForm"
                >
                    <!-- Signup Form -->
                    <v-row>
                        <v-col
                            class="flex-shrink-1 flex-grow-0 pr-0"
                            align-self="end"
                        >
                            <v-img
                                src="/general/FixerCompanyIcon.png"
                                height="35px"
                                width="35px"
                                contain
                            />
                        </v-col>

                        <!-- Company Details -->
                        <v-col
                            class="flex-grow-1 flex-shrink-0 os-22-sb"
                            align-self="end"
                        >
                            Company Details
                        </v-col>
                        <v-col
                            class="flex-shrink-1 flex-grow-0 text-center os-13-r"
                            v-if="$vuetify.breakpoint.smAndUp"
                        >
                            <input
                                type="file"
                                style="display: none"
                                ref="avatar"
                                accept="image/*"
                                @change="avatarSelected"
                            >
                            <v-avatar
                                class="mb-2"
                                size="100"
                            >
                                <v-img
                                    :src="avatar ? avatar : '/landing/signup/general/LandingSignupGeneralUploadImage.png'"
                                    contain
                                />
                            </v-avatar>
                            <span @click="selectAvatar" style="color: rgb(143, 155, 179); cursor: pointer;">Upload Image</span>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            class="text-center os-13-r"
                            v-if="$vuetify.breakpoint.xs"
                            cols="12"
                        >
                            <input
                                type="file"
                                style="display: none"
                                ref="avatar"
                                accept="image/*"
                                @change="avatarSelected"
                            >
                            <v-avatar
                                class="mb-2"
                                size="100"
                            >
                                <v-img
                                    :src="avatar ? avatar : '/landing/signup/general/LandingSignupGeneralUploadImage.png'"
                                    contain
                                />
                            </v-avatar>
                            <br />
                            <span @click="selectAvatar" style="color: rgb(143, 155, 179); cursor: pointer;">Upload Image</span>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="pb-0 os-15-sb"
                        >
                            Company Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="nameRules"
                                v-model="company.company_name"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 pt-sm-3 os-15-sb"
                        >
                            Number of Employees<span style="color: red;">*</span>
                            <br />
                            <v-select
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="selectRules"
                                v-model="company.company_size"
                                :items="companySizes"
                                item-color="#2E567A"
                                item-text="name"
                                item-value="id"
                                return-object
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                        >
                            Company Registration
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                placeholder="This field is highly suggested"
                                v-model="company.company_registration"
                                :rules="registrationRules"
                                counter="50"
                            />
                        </v-col>
                        <!-- Company Details -->

                        <!-- Primaty Contact -->
                        <v-col
                            class="os-22-sb"
                            cols="12"
                            align-self="end"
                        >
                            Primary Contact
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="pb-0 os-15-sb"
                        >
                            First Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="nameRules"
                                v-model="company.first_name"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 pt-sm-3 os-15-sb"
                        >
                            Last Name<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="nameRules"
                                v-model="company.last_name"
                                counter="50"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                        >
                            Email<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :readonly="!canEditEmail"
                                v-model="company.email"
                                :rules="emailRules"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                        >
                            Contact Number
                            <br />
                            <vue-tel-input-vuetify
                                v-model="company.contact_number_1"
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                @input="contactNumberInput"
                                :rules="contactNumberRules"
                                defaultCountry="za"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                            v-if="passwordRequired"
                        >
                            New Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="company.password"
                                :append-icon="showPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="py-0 os-15-sb"
                            v-if="passwordRequired"
                        >
                            Confirm Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="confirmPassword"
                                :error-messages="passwordMatchError()"
                                :append-icon="showConfirmPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                @click:append="showConfirmPassword = !showConfirmPassword"
                            />
                        </v-col>
                        <!-- Primaty Contact -->

                        <!-- Back/Continue Buttons -->
                        <v-col
                            cols="12"
                            class="text-right pt-0 os-13-sb"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg mr-2 os-13-sb"
                                color="#2E567A"
                                style="width: 103px;"
                                @click="back"
                            >
                                Back
                            </v-btn>
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                style="width: 103px;"
                                @click="signup"
                                :disabled="!validSignupCompanyForm"
                            >
                                Continue
                            </v-btn>
                        </v-col>
                        <!-- Back/Continue Buttons -->
                    </v-row>
                    <!-- Signup Form -->
                </v-form>
            </v-col>
        </v-row>
        <!-- Mobile -->
    </div>
</template>

<script>
    import { CompanySignupController, LookupCompanySizeController } from '@/logic/controller/index.controller.js'
    import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

    export default {
        name: 'SignupCompanyPage',

        computed: {
            contactNumberRules () {
                // if (this.company.contact_number_1 && this.validContactNumber) return []
                if (!this.company.contact_number_1) return [ v => !!v || 'Number is required' ]
                else return [
                    v => /^\+[1-9]\d{8,15}$/.test(v.replace(/\s/g, '')) || 'Must be valid number'
                ]
            },

            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            registrationRules () {
                if (this.company.company_registration === null || this.company.company_registration === '') return []
                else if (this.company.company_registration.replace(/\s/g, '') === '') return [ 'Invalid registration number' ]
                else return [ v => v.length <= 50 || 'Max 50 characters' ]
            },

            nameRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            emailRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => /\S+@\S+\.\S+/.test(v) || 'Invalid e-mail address'
                ]
            },

            passwordRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field'
                ]
            },

            canEditEmail () {
                if (this.$store.state.signup.signupData.type === 'internal') return true
                else if (this.$store.state.signup.signupData.type === 'facebook' && !this.$store.state.signup.signupData.email) return true
                else return false
            },

            passwordRequired () {
                if (this.$store.state.signup.signupData.type === 'internal') return true
                else return false
            }
        },

        data () {
            return {
                validSignupCompanyForm: false,
                company: {
                    first_name: '',
                    last_name: '',
                    contact_number_1: '',
                    password: null,
                    email: '',
                    company_name: '',
                    company_registration: null,
                    company_size: {
                        id: null
                    },
                    password: ''
                },
                validContactNumber: false,
                avatar: null,
                avatarFormData: null,
                companySignupController: null,
                lookupCompanySizeController: null,
                companySizes: [],
                mdiEyeOutline,
                mdiEyeOffOutline,
                confirmPassword: '',
                showPassword: false,
                showConfirmPassword: false
            }
        },

        beforeMount () {
            if (!this.$store.state.signup.signupData.type) this.$store.commit('user/resetUserData')

            this.companySignupController = new CompanySignupController()
            this.lookupCompanySizeController = new LookupCompanySizeController()
        },

        async mounted () {
            if (this.$http.token) {
                if (this.$store.state.signup.signupData.email) this.company.email = this.$store.state.signup.signupData.email
                if (this.$store.state.signup.signupData.password) this.company.password = this.$store.state.signup.signupData.password
                this.company.facebook_id = this.$store.state.signup.signupData.facebook_id
            }

            await this.getCompanySizes()
        },

        methods: {
            async getCompanySizes () {
                try {
                    this.companySizes = await this.lookupCompanySizeController.get()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            back () {
                if (this.$route.path !== '/signup') this.$router.push('/signup')
            },

            async signup () {
                try {
                    const response = await this.companySignupController.create(this.company)
                    this.$http.token = response.token
                    if (this.avatarFormData) {
                        const uploadResponse = await this.companySignupController.uploadAvatar(response.company_id, this.avatarFormData)
                        response.avatar = uploadResponse.image_url
                    }

                    // delete response.token
                    await this.$store.commit('user/setUserData', response)

                    await this.$store.commit('setNewUser', true)

                    if (this.$route.path !== '/profile') this.$router.push('/profile')

                    window.gtag('event', 'signup_company')

                    await this.$store.commit('resetSignupData')
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            contactNumberInput (number, isValid, country) {
                if (isValid.number.international) this.company.contact_number_1 = isValid.number.international
                else this.company.contact_number_1 = isValid.number.input
            },

            passwordMatchError () {
                if (this.confirmPassword.replace(/\s/g, '') !== '') {
                    return (this.company.password === this.confirmPassword) ? '' : 'Passwords must match'
                } else return ''
            },

            selectAvatar () {
                this.$refs.avatar.click()
            },

            avatarSelected (e) {
                const files = e.target.files

                if (files && files.length > 0) {
                    var reader = new FileReader()
                    var f = files[0]

                    var self = this

                    reader.onloadend = function () {
                        self.avatar = reader.result
                        self.avatarFormData = new FormData()
                        self.avatarFormData.append('image', files[0])
                    }

                    reader.readAsDataURL(f)
                }
            }
        }
    }
</script>
<style>
    @import url('../../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }

    .country-code {
        margin-right: 12px;
    }
</style>
<style scoped>
    >>> .v-input__slot.white {
        font-size: 13px !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>